<template>
  <div>
    <main v-if="NosologyDetail && Object.keys(NosologyDetail).length">
      <div class="top-banner">
        <picture v-if="formattedImageList && formattedImageList.length">
          <source
            v-for="(img, ind) in formattedImageList"
            :key="ind"
            :srcset="img.src"
            :media="`(max-width: ${img.media}px)`"
          />
          <img
            class="top-banner__banner-image"
            :src="formattedImageList[formattedImageList.length - 1].src"
          />
        </picture>
        <div class="content">
          <div class="top-banner__banner-text">
            <div class="top-banner__banner-caption">
              <img class="mr-2" :src="$root.host + NosologyDetail.icon" />
              {{ NosologyDetail.title }}
            </div>
            <div
              class="top-banner__banner-description"
              v-html="NosologyDetail.banner_description"
            ></div>
          </div>
        </div>
      </div>
      <Breadcrumbs
        :pages="[
          { name: 'Терапевтические области', link: { name: 'Nosology' } },
          { name: NosologyDetail.title },
        ]"
      />
      <section class="therapy-areas cardio-page__section">
        <div class="content">
          <div class="cardio-page__row">
            <div class="cardio-page__categories">
              <div class="cardio-page__title">Заболевания</div>
              <div class="cardio-page__categories-row">
                <DiseaseItem
                  v-for="(item, ind) in diseasesList"
                  :key="ind"
                  :item="ItemList(item)"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <div v-else-if="NosologyDetail === 404">
      <Choch class="inPage" />
    </div>
    <div v-else-if="NosologyDetail === false"><Error class="inPage" /></div>
    <div v-else class="preloader detail-page__preloader">
      <Preloader class="" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DiseaseItem from "../components/main/DiseaseItem.vue";
import Breadcrumbs from "../components/pageComponents/Breadcrumbs.vue";
import Choch from "../views/404.vue";
import Error from "../views/404.vue";
import Preloader from "@/components/Preloader.vue";
export default {
  metaInfo() {
    if (this.NosologyDetail.title) {
      return { title: this.NosologyDetail.title.replace(/<[^>]*>?/gm, "") };
    } else {
      return { title: this.NosologyDetail.title };
    }
  },
  components: { DiseaseItem, Breadcrumbs, Choch, Error, Preloader },
  name: "NosologyDetail",
  data: () => ({
    media: {
      mobile: 767,
      tablet: 1220,
      desktop: 1919,
      desktop_l: 2559,
      desktop_xl: 3839,
      desktop_xxl: 9999999,
    },
  }),
  computed: {
    ...mapGetters(["NosologyDetail"]),
    diseasesList() {
      let arr = [...this.NosologyDetail.subgroups, ...this.NosologyDetail.links]
      return arr
    },
    formattedImageList() {
      let imageList = [];
      let usedImages = Object.keys(this.NosologyDetail)
        .filter((el) => el.includes("banner_"))
        .map((el) => el.replace("banner_", ""));
      for (let imgFormat of usedImages) {
        if (
          this.NosologyDetail["banner_" + imgFormat] &&
          Object.keys(this.media).includes(imgFormat)
        ) {
          imageList.push({
            src:
              this.$root.host +
              this.NosologyDetail["banner_" + imgFormat].replace(
                "http:",
                "https:"
              ),
            media: this.media[imgFormat],
          });
        }
      }
      imageList.sort((a, b) => (a.media > b.media ? 1 : -1));
      return imageList;
    },
  },
  methods: {
    ...mapActions(["fetchNosologyDetail"]),
    ItemList(item, tag) {
      let imageList = [];
      imageList.push({
        src: require("@/assets/img/cardio-img/main-5_mob.jpg"),
        media: "(max-width: 99999999px)",
      });
      item.image = imageList;
      return item;
    },
  },
  mounted() {
    if (!Object.keys(this.NosologyDetail).length ) {
      this.fetchNosologyDetail({ slug: this.$route.params.slug, access: this.$route.query.access || null });
    }
  },
  watch: {
    "$route.params.slug": {
      handler: async function () {
        // if (!this.$route.query.access) {
          this.fetchNosologyDetail({ slug: this.$route.params.slug, access: this.$route.query.access || null });
        // }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" >
main {
  .top-banner {
    position: relative;
    height: 497px;
    @media screen and (max-width: 1220px) {
      height: 399px;
    }
    @media screen and (max-width: 767px) {
      height: 540px;
    }
    .content {
      height: 100%;
    }
    &__banner {
      position: relative;
      padding-top: 62px;
      padding-bottom: 132px;
      height: 497px;

      @media screen and (max-width: 1220px) {
        padding-top: 40px;
        height: 399px;
      }

      @media screen and (max-width: 767px) {
        height: 540px;
        padding-top: 24px;
      }

      &-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        display: block;
      }

      &-text {
        position: relative;
        z-index: 2;
        width: 585px;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 65px 0;

        @media screen and (max-width: 1220px) {
          width: 100%;
          padding: 40px 0;
        }

        @media screen and (max-width: 767px) {
          width: auto;
        }
      }

      &-caption {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: "Roboto Slab", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 42px;
        line-height: 48px;
        color: #830051;

        @media screen and (max-width: 767px) {
          font-size: 24px;
          line-height: 32px;
          padding-right: 47px;
        }
        img {
          width: 48px;
          height: 48px;
          object-fit: contain;
        }
      }

      &-description {
        margin-top: auto;
        padding-top: 24px;
        border-top: 1px solid #b2b4b4;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #3c4242;

        @media screen and (max-width: 1220px) {
          width: 100%;
        }

        @media screen and (max-width: 767px) {
          width: auto;
          border-top: 0;
          margin-top: 16px;
          padding-top: 0;
        }
      }
    }
  }
  .cardio-page {
    &__row {
      display: grid;
      column-gap: 32px;
      .area-item {
        &__title {
          color: #ffffff;
          font-weight: 700;
          font-size: 28px;
          line-height: 34px;
          @media screen and (max-width: 1220px) {
            font-weight: 500;
            font-size: 28px;
            line-height: 32px;
          }
          @media screen and (max-width: 767px) {
            margin-top: 0;
            font-weight: 500;
            font-size: 22px;
            line-height: 26px;
          }
        }
        &__image {
          @media screen and (max-width: 767px) {
            height: 116px;
          }
        }
        &__title {
          @media screen and (max-width: 767px) {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
            padding: 34px 24px;
          }
        }
        &__button {
          background-color: transparent;
          color: #ffffff;
        }
      }
      @media screen and (max-width: 1220px) {
        display: block;
      }
    }

    &__section {
      padding-top: 64px;

      @media screen and (max-width: 1220px) {
        padding-top: 40px;
      }
    }

    &__title {
      margin-bottom: 40px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;

      @media screen and (max-width: 1220px) {
        margin-bottom: 32px;
      }
    }

    &__categories-row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 24px;
      row-gap: 24px;

      @media screen and (max-width: 1220px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 16px;
      }
    }

    &__materials-row {
      display: grid;
      grid-template-columns: 492px 692px;
      grid-template-rows: 1fr;
      column-gap: 32px;
      row-gap: 24px;

      @media screen and (max-width: 1220px) {
        display: block;
      }
    }

    &__categories {
      @media screen and (max-width: 1220px) {
        margin-bottom: 40px;
      }
    }
  }
}
</style>
